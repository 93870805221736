<template>
  <div class="col position-relative">
    <div class="card shadow-none border-0 mb-0 rounded-0">
      <div class="card-body pb-0">
        <full-calendar
          ref="refCalendar"
          :options="calendarOptions"
          class="full-calendar"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import esLocale from "@fullcalendar/core/locales/pt"
import { mapState } from 'vuex'

import axios from 'axios'
import { baseApiUrl } from '@/global'   

export default {
  components: {
    FullCalendar,
  },
  computed: {
    ...mapState(['user'])
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridWeek',
        locale: esLocale,
        eventColor: '#378006',
        events: []
      }, 
    }
  },
  methods: {
    getSchedules() {
      axios.post(`${baseApiUrl}/agenda`, {
        db: this.user.user.produto.db
      })
        .then(res => {
          if(res.data.success) {
            this.calendarOptions.events = res.data.data
          }
        })
    }
  },
  mounted() {
    this.getSchedules()
  }
};
</script>

<style>
</style>